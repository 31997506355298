import $ from "jquery";

document.addEventListener(
    `DOMContentLoaded`,
    function () {
        $(".ms_tag-filter .smart-tags").change(function () {
            const $hiddenInput = $(".ms_tag-filter .hidden-input");
            const $emptyInput = $(".ms_tag-filter .empty-input");
            const $smartTag = $(this).val();

            $.ajax({
                url: themosis.ajaxurl,
                type: "POST",
                cache: false,
                data: {
                    action: "populate_posts_with_tag",
                    smart_tag: $smartTag,
                },
                success: function (data) {
                    const $select = $(".ms_tag-filter .posts-with-tag");
                    if (!data || data.length === 0) {
                        $select.html(`<option value="" selected disabled>${$emptyInput.val()}</option>`);
                    } else {
                        $select.html(`<option value="" selected disabled>${$hiddenInput.val()}</option>${data}`);
                    }
                },
            });
        });

        $(".ms_tag-filter .posts-with-tag").change(function () {
            const options = this.options;
            const selectedOption = this.options[this.selectedIndex];
            const selectedSmartTagLabel =
                document.querySelector(".ms_tag-filter .smart-tags").options[
                    document.querySelector(".ms_tag-filter .smart-tags").selectedIndex
                ].label;

            if (options && selectedOption.value !== "") {
                if (typeof ga !== "undefined" && typeof ga.getAll === "function") {
                    new Promise((resolve, _) => {
                        const analyticsGTMID = ga.getAll()[0].get("trackingId");
                        ga(
                            `gtag_${analyticsGTMID}.send`,
                            "event",
                            "Tag filter",
                            "redirect",
                            selectedSmartTagLabel + " -> " + selectedOption.label
                        );
                        resolve();
                    }).then(() => {
                        // Redirect to the selected option.
                        window.location = this.options[this.selectedIndex].value;
                    });
                } else {
                    // Redirect to the selected option.
                    window.location = this.options[this.selectedIndex].value;
                }
            }
        });
    },
    { passive: true }
);

// Add classes to blocks that normally don't have classes.
const addClassToBlock = (props, blockType, attributes) => {
    const notDefined = typeof props.className === "undefined" || !props.className ? true : false;

    if (blockType.name === "core/list") {
        return Object.assign(props, {
            className: notDefined ? `wp-block-list` : `wp-block-list ${props.className}`,
        });
    }

    // if (blockType.name === "core/paragraph") {
    //   return Object.assign(props, {
    //     className: notDefined
    //       ? "wp-block-paragraph"
    //       : `wp-block-paragraph ${props.className}`
    //   });
    // }

    return props;
};

if (wp.hooks !== undefined) {
    wp.hooks.addFilter("blocks.getSaveContent.extraProps", "wp-soup/add-class-to-blocks", addClassToBlock);
}

/**
 * returns blocks on page, all on same level (instead of innerblocks)
 * @returns [{}]
 */
function wpSoupGetBlocksOnPage() {
    let blocks = [];
    // get all blocks, and call recursive function for it
    wp.data
        .select("core/block-editor")
        .getBlocks()
        .forEach((block) => {
            blocks = [...blocks, ...wpSoupGetBlocksFromBlock(block)];
        });
    return blocks;
}

/**
 * recursive method for wpSoupGetBlocksOnPage()
 * @param block
 * @returns [{}]
 */
function wpSoupGetBlocksFromBlock(block) {
    let blocks = [block];
    if (block.hasOwnProperty("innerBlocks")) {
        block.innerBlocks.forEach((inner_block) => {
            blocks = [...blocks, ...wpSoupGetBlocksFromBlock(inner_block)];
        });
    }
    return blocks;
}

if (wp.domReady !== undefined) {
    wp.domReady(() => {
        /**
         * Deprecated blocks
         * `wp_soup_deprecated_blocks` gets shared from php, it's a constant array
         * of strings
         */
        if (typeof wp_soup_deprecated_blocks !== "undefined") {
            const available_blocks = wp.blocks.getBlockTypes();
            const blocks_in_page = wpSoupGetBlocksOnPage();

            wp_soup_deprecated_blocks.forEach((deprecated_block) => {
                // check if the deprecated block is used on this page
                const current_result = blocks_in_page.find((block) => {
                    return block.name === deprecated_block;
                });

                // if it's on the page, set it in the deprecated blocks category
                // we do this because unregistering it would break the page.
                if (current_result !== undefined) {
                    const result = available_blocks.find((block) => {
                        return block.name === deprecated_block;
                    });
                    if (result !== undefined) {
                        result.category = "deprecated";
                    }
                }

                // if it's not used on the page, we can fully remove it instead
                else {
                    wp.blocks.unregisterBlockType(deprecated_block);
                }
            });
        }
    });
}

document.addEventListener(
    "DOMContentLoaded",
    () => {
        for (let i = 1; i < 10; i++) {
            const clampTo = document.querySelectorAll(`.clamp-to-${i}`);
            for (c of clampTo) {
                window.$clamp(c, { clamp: i });
            }
        }
    },
    { passive: true }
);

import axios from "/srv/www/f/flie.nl/vhosts/www/htdocs/wp-content/plugins/wp-soup-framework/node_modules/axios";

document.addEventListener(
    `DOMContentLoaded`,
    () => {
        const favoriteButtons = document.querySelectorAll(".favorite-button");
        for (const button of favoriteButtons) {
            button.addEventListener(
                "click",
                function (event) {
                    const postID = this.dataset.postId;
                    if (!postID) {
                        return;
                    }

                    const action = this.dataset.action;
                    if (action === "add") {
                        addToFavorites(postID, button);
                    } else if (action === "remove") {
                        removeFromFavorites(postID, button);
                    }
                },
                { passive: true }
            );
        }

        function addToFavorites(postID, button) {
            let formData = new FormData();
            formData.append("action", "add_to_favorites");
            formData.append("security", themosis.nonce);
            formData.append("post_id", postID);

            axios.post(themosis.ajaxurl, formData).then((response) => {
                if (response.data.success) {
                    button.classList.add("is-favorited");
                    button.querySelector("iconify-icon").outerHTML = themosis.wpsoup.favorites.button_icon_favorited;
                    button.dataset.action = "remove";
                }
            });
        }

        function removeFromFavorites(postID, button) {
            let formData = new FormData();
            formData.append("action", "remove_from_favorites");
            formData.append("security", themosis.nonce);
            formData.append("post_id", postID);

            axios.post(themosis.ajaxurl, formData).then((response) => {
                if (response.data.success) {
                    button.classList.remove("is-favorited");
                    button.querySelector("iconify-icon").outerHTML = themosis.wpsoup.favorites.button_icon;
                    button.dataset.action = "add";
                }
            });
        }
    },
    { passive: true }
);

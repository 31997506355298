import $ from "jquery";

window.addEventListener(
    "load",
    () => {
        if (Foundation.MediaQuery.atLeast("large")) {
            const directions = ["left", "right"];
            directions.forEach((direction) => {
                const columns = $(`.wp-block-column.half-width-image-${direction}`);
                if (columns.length) {
                    columns.each(function () {
                        const column = $(this);
                        const container = $(this).closest(".wp-block-ms-container");
                        const containerId = container.attr("id");
                        const img = $(this).find("img");
                        let src = img.attr("src");
                        if (typeof src === "undefined") {
                            return;
                        }

                        if (typeof LazyLoad === "function") {
                            if (!src.includes("data:image/svg+xml")) {
                                img.parent().css("display", "none");
                                column.append(
                                    `<style>#${containerId} .wp-block-column.half-width-image-${direction}:before { background-image: url(${src}); }</style>`
                                );
                            }

                            img.on("load", function () {
                                src = img.attr("src");
                                img.parent().css("display", "none");
                                column.append(
                                    `<style>#${containerId} .wp-block-column.half-width-image-${direction}:before { background-image: url(${src}); }</style>`
                                );
                            });
                        } else {
                            if (!src.includes("data:image/svg+xml")) {
                                img.parent().css("display", "none");
                                column.append(
                                    `<style>#${containerId} .wp-block-column.half-width-image-${direction}:before { background-image: url(${src}); }</style>`
                                );
                            }
                        }
                    });
                }
            });
        }
    },
    { passive: true }
);

document.addEventListener(
    `DOMContentLoaded`,
    () => {
        /**
         * Add change event to the price toggle switch to show/hide prices.
         */
        const priceToggle = document.querySelector(".ms_pricing .js-time-period-switch input");
        const priceContent = document.querySelectorAll(".ms_pricing .price-content");
        const extraPriceContent = document.querySelectorAll(".ms_pricing .extra-price-content");
        if (priceToggle) {
            priceToggle.addEventListener(
                "change",
                () => {
                    if (priceContent) {
                        for (const priceBlock of priceContent) {
                            priceBlock.classList.toggle("hide");
                        }
                    }
                    if (extraPriceContent) {
                        for (const priceBlock of extraPriceContent) {
                            priceBlock.classList.toggle("hide");
                        }
                    }
                    Foundation.reInit("equalizer");
                },
                { passive: true }
            );
        }

        const priceBlocks = document.querySelectorAll(".ms_pricing .price-block");
        if (priceBlocks) {
            for (const block of priceBlocks) {
                const title = block.querySelector(".title").textContent;
                const buttonWC = block.querySelector(".cta-button.woocommerce a");
                const buttonWCHref = buttonWC ? buttonWC.href : undefined;
                const button = block.querySelector(".cta-button:not(.woocommerce) a");
                const buttonHref = button ? button.href : undefined;
                const extraOptions = block.querySelectorAll(".extra-options input");
                let selectedOptionsID = [];
                let selectedOptions = [];
                if (extraOptions) {
                    for (const [index, option] of extraOptions.entries()) {
                        const optionName = option.parentElement.parentElement.textContent.trim();
                        const optionID = option.parentElement.parentElement.dataset.id;
                        const firstPriceNode = block.querySelector(".price-content.first .price .number");
                        const secondPriceNode = block.querySelector(".price-content.second .price .number");
                        option.addEventListener(
                            "change",
                            function () {
                                const optionPrice = parseFloat(this.dataset.price);
                                const optionPrice2 = parseFloat(this.dataset.secondPrice);
                                // Apply first extra option price to "left label price".
                                if (optionPrice) {
                                    // First price.
                                    if (firstPriceNode) {
                                        const firstPrice = parseFloat(
                                            firstPriceNode.textContent.replace(/(\d),(?=\d)/g, "$1.")
                                        );
                                        const newFirstPriceHTML = this.checked
                                            ? firstPrice + optionPrice
                                            : firstPrice - optionPrice;
                                        firstPriceNode.textContent = newFirstPriceHTML
                                            .toFixed(2)
                                            .replace(".", ",")
                                            .replace(",00", "");
                                    }
                                }
                                // Apply second extra option price to "right label price".
                                if (optionPrice2) {
                                    // Second price.
                                    if (secondPriceNode) {
                                        const secondPrice = parseFloat(
                                            secondPriceNode.textContent.replace(/(\d),(?=\d)/g, "$1.")
                                        );
                                        const secondPriceHTML = this.checked
                                            ? secondPrice + optionPrice2
                                            : secondPrice - optionPrice2;
                                        secondPriceNode.textContent = secondPriceHTML
                                            .toFixed(2)
                                            .replace(".", ",")
                                            .replace(",00", "");
                                    }
                                }

                                // Add parameters to the URL if extra options have been selected.
                                if (title && button) {
                                    // Add or remove selected extra option.
                                    this.checked
                                        ? selectedOptions.splice(index, 0, optionName)
                                        : (selectedOptions = selectedOptions.filter((value) => value !== optionName));
                                    // Create the query string for the button.
                                    const params = new URLSearchParams();
                                    const options = selectedOptions.join(" & ");
                                    const newParams = {
                                        ...(options !== "" && { "extra-options": options }),
                                    };
                                    for (const [key, val] of Object.entries(newParams)) {
                                        params.append(key, val);
                                    }
                                    button.href = buttonHref + params.toString();
                                }

                                if (buttonWC && optionID) {
                                    // Add or remove selected extra option.
                                    this.checked
                                        ? selectedOptionsID.splice(index, 0, optionID)
                                        : (selectedOptionsID = selectedOptionsID.filter((value) => value !== optionID));
                                    const optionsString = selectedOptionsID.join(",");
                                    buttonWC.href =
                                        optionsString !== "" ? buttonWCHref + "," + optionsString : buttonWCHref;
                                    buttonWC.dataset.id = buttonWC.href.split("=")[1];
                                }
                            },
                            { passive: true }
                        );
                    }
                }
            }
        }
    },
    { passive: true }
);

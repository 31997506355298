import $ from "jquery";

$(function () {
    if ($(".woocommerce .product-image-slider").length) {
        const galleryThumbs = new window.Swiper(".woocommerce .product-thumbnail-slider", {
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: true,
            },
        });

        if ($(".woocommerce .product-thumbnail-slider").length) {
            const galleryTop = new window.Swiper(".woocommerce .product-image-slider", {
                autoHeight: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                thumbs: {
                    swiper: galleryThumbs,
                },
            });
        }
    }
});

$(document).on("change input", ".woocommerce-cart-form .cart_item :input", function (e) {
    $('.woocommerce-cart-form :input[name="update_cart"]').prop("disabled", false);
});

$(document.body).on("change blur", "form.woocommerce-cart-form .qty-field select", function (e) {
    const select = $(this);
    const value = select.val();
    const parent = select.parent();
    parent.find("input").val(value);
});

import $ from "/srv/www/f/flie.nl/vhosts/www/htdocs/wp-content/plugins/wp-soup-framework/node_modules/jquery";

$(function () {
  document.addEventListener("facetwp-loaded", function () {
    $(document).foundation();
  });

  $('.ginput_product_price_wrapper').append('<span class="ginput_product_price_label">excl. BTW</span>');
  $('.ginput_subtotal').after(' excl. BTW');

  if($('.single-post .category-evenement').find('.ms_archive future-events .wrapper').length === 0) {
      $('.single-post .category-evenement h2.title').remove();
      $('.single-post .category-evenement .future-events').remove();
  }
});
$(document).ready(function() {

  if($('.single-post .category-evenement').find('.ms_archive future-events .wrapper').length === 0) {
    $('.single-post .category-evenement h2.title').remove();
    $('.single-post .category-evenement .future-events').remove();
  }
});
$(window).on("load", function () {

  if($('.single-post .category-evenement').find('.ms_archive future-events .wrapper').length === 0) {
    $('.single-post .category-evenement h2.title').remove();
    $('.single-post .category-evenement .future-events').remove();
  }
});

import $ from "jquery";

document.addEventListener(
    `DOMContentLoaded`,
    () => {
        /**
         * Add submenu toggle functionality.
         */
        const menus = document.querySelectorAll(".js-ms-has-submenu");
        let i = 0;
        // For every menu item with a submenu.
        for (const menu of menus) {
            // Apply id for toggling purposes.
            const randomID = "id-" + i++;
            const submenu = menu.querySelector(".submenu");
            if (submenu) {
                submenu.setAttribute("id", randomID);
            }
            const toggle = menu.querySelector(".toggle");
            if (toggle) {
                toggle.setAttribute("data-toggle", randomID);
            }

            // On toggling.
            $(`#${randomID}`).on("on.zf.toggler off.zf.toggler", function (event) {
                if (event.target !== this) return;

                switch (event.type) {
                    case "on":
                        $(this).slideDown({
                            start: function () {
                                $(this).css({
                                    display: "flex",
                                });
                            },
                        });
                        break;
                    case "off":
                        $(this).slideUp();
                        break;
                }

                // Recalculate sticky height if sticky exists.
                if ($(".ms-menu .sticky").length) {
                    $(".ms-menu .sticky").foundation("_calc", true);
                }
            });
        }

        // Recalculate sticky height on toggling submenu.
        $(".menu-mobile").on("on.zf.toggler off.zf.toggler", function (event) {
            if (event.target !== this) return;

            switch (event.type) {
                case "on":
                    $(this).slideDown();
                    break;
                case "off":
                    $(this).slideUp();
                    break;
            }

            // Recalculate sticky height if sticky exists.
            if ($(".ms-menu .sticky").length) {
                $(".ms-menu .sticky").foundation("_calc", true);
            }
        });

        // Properly update the ARIA states on focus (keyboard) and mouse over events.
        $('[role="menubar"]').on("focus.aria  mouseenter.aria", '[aria-haspopup="true"]', (event) => {
            $(event.currentTarget).attr("aria-expanded", true);
        });

        // Properly update the ARIA states on blur (keyboard) and mouse out events.
        $('[role="menubar"]').on("blur.aria  mouseleave.aria", '[aria-haspopup="true"]', (event) => {
            $(event.currentTarget).attr("aria-expanded", false);
        });

        const overlayToggles = document.querySelectorAll(".trigger-overlay");
        for (const toggle of overlayToggles) {
            toggle.addEventListener(
                "click",
                () => {
                    const body = document.querySelector("body");
                    const logo = document.querySelector(".logo-image-overlay");
                    const logoMobile = document.querySelector(".logo-mobile-image-overlay");
                    const language = document.querySelector(".language-block");
                    const overlay = document.querySelector(".top-bar-overlay");
                    const overlayItems = overlay.querySelectorAll("li");
                    if (overlay.classList.contains("visible")) {
                        body.classList.remove("overlay-visible");
                        overlay.classList.remove("visible");
                        if (logo) {
                            logo.classList.remove("fadeInUp");
                        }
                        if (logoMobile) {
                            logoMobile.classList.remove("fadeInUp");
                        }
                        if (language) {
                            language.classList.remove("fadeInUp");
                        }
                        for (const li of overlayItems) {
                            li.classList.remove("fadeInUp");
                        }
                    } else {
                        body.classList.add("overlay-visible");
                        overlay.classList.add("visible");
                        if (logo) {
                            logo.classList.add("fadeInUp");
                        }
                        if (logoMobile) {
                            logoMobile.classList.add("fadeInUp");
                        }
                        if (language) {
                            language.classList.add("fadeInUp");
                        }
                        for (const li of overlayItems) {
                            li.classList.add("fadeInUp");
                        }
                    }
                },
                { passive: true }
            );
        }

        const toggleArrow = document.querySelector(".hide.icon-arrow");
        if (toggleArrow) {
            const submenuTogglesCheckInterval = setInterval(function () {
                const submenuToggles = document.getElementsByClassName("submenu-toggle");

                if (submenuToggles.length !== 0) {
                    for (let toggle of submenuToggles) {
                        if (toggle.getElementsByTagName("svg").length === 0) {
                            toggle.innerHTML += toggleArrow.innerHTML;
                        }
                    }

                    clearInterval(submenuTogglesCheckInterval);
                }
            });
        }

        const searchBlockButton = document.querySelector(".search-block-button");
        const searchFormBlock = document.querySelector(".search-form-block");
        if (searchBlockButton) {
            searchBlockButton.addEventListener(
                "click",
                () => {
                    searchBlockButton.classList.toggle("visible");
                    if (searchFormBlock) {
                        searchFormBlock.classList.toggle("visible");
                    }
                },
                { passive: true }
            );
        }
    },
    { passive: true }
);

(() => {
    const backTop = document.getElementsByClassName("js-back-to-top")[0];
    if (backTop) {
        const dataElement = backTop.getAttribute("data-element");
        const scrollElement = dataElement ? document.querySelector(dataElement) : window;

        const //scroll to top duration
            scrollDuration = parseInt(backTop.getAttribute("data-duration")) || 300;

        const //show back-to-top if scrolling > scrollOffset
            scrollOffset = parseInt(backTop.getAttribute("data-offset")) || 0;

        let scrolling = false;

        //detect click on back-to-top link
        backTop.addEventListener("click", (event) => {
            event.preventDefault();
            if (!window.requestAnimationFrame) {
                scrollElement.scrollTo(0, 0);
            } else {
                dataElement ? scrollTo(0, scrollDuration, false, scrollElement) : scrollTo(0, scrollDuration);
            }
            //move the focus to the #top-element - don't break keyboard navigation
            moveFocus(document.getElementById(backTop.getAttribute("href").replace("#", "")));
        });

        //listen to the window scroll and update back-to-top visibility
        checkBackToTop();
        if (scrollOffset > 0) {
            scrollElement.addEventListener(
                "scroll",
                (event) => {
                    if (!scrolling) {
                        scrolling = true;
                        !window.requestAnimationFrame
                            ? setTimeout(() => {
                                  checkBackToTop();
                              }, 250)
                            : window.requestAnimationFrame(checkBackToTop);
                    }
                },
                { passive: true }
            );
        }

        function checkBackToTop() {
            let windowTop = scrollElement.scrollTop || document.documentElement.scrollTop;
            if (!dataElement) {
                windowTop = window.scrollY || document.documentElement.scrollTop;
            }
            if (windowTop >= scrollOffset) {
                backTop.classList.add("back-to-top--is-visible");
            } else {
                backTop.classList.remove("back-to-top--is-visible");
            }
            scrolling = false;
        }

        function scrollTo(final, duration, cb, scrollEl) {
            const element = scrollEl || window;
            let start = element.scrollTop || document.documentElement.scrollTop;
            let currentTime = null;

            if (!scrollEl) start = window.scrollY || document.documentElement.scrollTop;

            const animateScroll = (timestamp) => {
                if (!currentTime) currentTime = timestamp;
                let progress = timestamp - currentTime;
                if (progress > duration) progress = duration;
                const val = Math.easeInOutQuad(progress, start, final - start, duration);
                element.scrollTo(0, val);
                if (progress < duration) {
                    window.requestAnimationFrame(animateScroll);
                } else {
                    cb && cb();
                }
            };

            window.requestAnimationFrame(animateScroll);
        }

        function moveFocus(element) {
            if (!element) element = document.getElementsByTagName("body")[0];
            element.focus();
            if (document.activeElement !== element) {
                element.setAttribute("tabindex", "-1");
                element.focus();
            }
        }

        Math.easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };
    }
})();

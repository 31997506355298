import $ from "jquery";

if (wp && wp.data) {
    if ($(window).width() > 600 && $("body").hasClass("custom-admin-styling")) {
        $("html").css("padding-top", "50px");
    }

    $(window).on("resize", () => {
        if ($("body").hasClass("custom-admin-styling")) {
            if ($(window).width() > 600) {
                if ($("html").css("padding-top") != "50px") {
                    $("html").css("padding-top", "50px");
                }
            } else {
                $("html").css("padding-top", "");
            }
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        const gutenbergLoadCheck = setInterval(() => {
            const anchors = $("[data-block] a");
            if (anchors.length > 0) {
                anchors.on("click", (e) => {
                    e.preventDefault();
                });

                clearInterval(gutenbergLoadCheck);
            }
        }, 100);

        const editPost = wp.data.select("core/edit-post");
        if (editPost) {
            editPost.isFeatureActive("fullscreenMode") &&
                wp.data.dispatch("core/edit-post").toggleFeature("fullscreenMode");
        }
    });
}

document.addEventListener(
    "DOMContentLoaded",
    () => {
        // Load all blocks on the page.
        const featuredMenuItems = document.querySelectorAll(".ms_featured-menu-items");
        for (menu of featuredMenuItems) {
            // Get all previews and menu links from current block.
            const previews = menu.querySelectorAll(".preview");
            const menuLinks = menu.querySelectorAll("a");
            for (link of menuLinks) {
                // Get the target.
                const target = link.dataset?.target;
                link.addEventListener(
                    "mouseover",
                    (event) => {
                        for (preview of previews) {
                            if (preview.dataset?.content === target) {
                                preview.classList.remove("hide");
                            } else {
                                preview.classList.add("hide");
                            }
                        }
                    },
                    { passive: true }
                );
            }
        }
    },
    { passive: true }
);

// Masonry layout.
import Macy from "macy";

document.addEventListener(
    "DOMContentLoaded",
    () => {
        if (typeof masonryGrids !== "undefined") {
            // Loop through each registered masonry grids and initialize them.
            masonryGrids = masonryGrids.map(({ container, id }) => {
                const instance = Macy({
                    container,
                    columns: 1,
                    trueOrder: true,
                    waitForImages: true,
                    mobileFirst: true,
                    breakAt: {
                        640: 2,
                        1024: 3,
                    },
                });
                instance.recalculate();

                // Return instance for usage with AJAX content.
                return {
                    id,
                    container,
                    instance,
                };
            });
        }
    },
    { passive: true }
);

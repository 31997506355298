import $ from "jquery";
import axios from "axios";

$(() => {
    $(".load-more").on("click", function () {
        const id = $(this).data("id");
        const fields = $(this).data("fields");
        if (!fields) {
            return;
        }

        const initialOffset = $(this).data("initial-offset") ?? 0;
        const offset = $(`#${id} .related-pages .related-page-card:not([class$="included"])`).length ?? 0;
        const masonry = $(this).data("is-masonry") ?? 0;
        const slider = $(this).data("is-slider") ?? 0;
        const textLoadMore = $(this).data("text-load-more");
        const textLoading = $(this).data("text-loading");
        const textLoadFailed = $(this).data("text-load-failed");

        // Data to send back to WordPress.
        const formData = new FormData();
        formData.append("action", "load_posts_by_ajax");
        formData.append("security", themosis.nonce);
        formData.append("offset", initialOffset + offset);
        formData.append("fields", JSON.stringify(fields));

        // Change button text to loading text.
        $(`#${id} .load-more`).html(textLoading);

        // Do request.
        axios
            .post(themosis.ajaxurl, formData)
            .then(({ data }) => {
                // Create a placeholder div to store retrieved posts.
                const cards = document.createElement("div");
                cards.innerHTML = data;

                // If retrieved posts length is smaller than the amount to load,
                // hide the button, else replace text with initial text.
                if (cards.children.length < fields.ajax_amount_to_load) {
                    $(`#${id} .load-more`).remove();
                } else {
                    $(`#${id} .load-more`).html(textLoadMore);
                }

                // Append loaded posts to the end.
                $(`#${id} .related-pages`).append(data);

                // Reinit masonry or equalizer.
                if (slider) {
                    const instance = cardSliders.find((x) => x.id === id).instance;
                    instance.update();
                }

                // Reinit masonry or equalizer.
                if (masonry) {
                    const instance = masonryGrids.find((x) => x.id === id).instance;
                    instance.runOnImageLoad(function () {
                        instance.recalculate(true);
                    }, true);
                } else {
                    Foundation.reInit("equalizer");
                }
            })
            .catch((error) => {
                $(`#${id} .load-more`).html(textLoadFailed);
            });
    });
});

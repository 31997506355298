window.addEventListener(
    "load",
    () => {
        // Do IE stuff
        if (window.document.documentMode) {
            if (Foundation.MediaQuery.current !== "small") {
                const columns = document.querySelectorAll(".wp-block-column");
                for (const column of columns) {
                    const flexBasis = column.style.flexBasis;
                    const isMargin = column.parentElement.classList.contains("margin");
                    if (flexBasis && !isMargin) {
                        column.style.setAttribute("width", flexBasis);
                        column.style.setProperty("flex-basis", "auto", "important");
                    } else if (flexBasis && isMargin) {
                        column.style.setAttribute("width", `calc(${flexBasis} - 2rem)`);
                        column.style.setProperty("flex-basis", "auto", "important");
                    }
                }
            }
        } else {
            if (Foundation.MediaQuery.current !== "small") {
                const columns = document.querySelectorAll(".wp-block-column");
                for (const column of columns) {
                    const flexBasis = column.style.flexBasis;
                    const isMargin = column.parentElement.classList.contains("margin");
                    if (flexBasis && isMargin) {
                        column.style.setProperty("flex-basis", `calc(${flexBasis} - 2rem)`, "important");
                    }
                }
            }
        }
    },
    { passive: true }
);

document.addEventListener(
    "DOMContentLoaded",
    () => {
        const changeOption = (selector, label) => {
            const field = document.querySelector(selector);
            if (field) {
                const option = field.querySelector("option:first-child");
                if (option) {
                    option.innerHTML = label;
                    option.disabled = true;
                }
            }
        };

        changeOption('#buddypress select[name$="day"]', "Geboortedag");
        changeOption('#buddypress select[name$="month"]', "Geboortemaand");
        changeOption('#buddypress select[name$="year"]', "Geboortejaar");
    },
    { passive: true }
);
